.status-card {
  padding: 1.875em;
  display: flex;
  align-items: center;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.5s ease 0s;
}

.status-card i.material-icons {
  font-size: 16px;
  align-self: center;
}

.status-card__icon {
  width: 30%;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.status-card__info {
  flex-grow: 1;
  text-align: center;
  z-index: 1;
  text-transform: capitalize;
}

.status-card__info > div.status-card__title {
  font-size: 1.375em;
  margin-bottom: 8px;
  font-weight: 900;
}

.status-card__info span.detail {
  font-size: 1em;
  color: var(--txt-gray-color);
  align-items: center;
  padding: 5px 0px;
}

.status-card__info span.detail > span {
  font-size: 12px;
  border-radius: 4px;
  height: 26px;
  margin-left: 10px;
  padding: 5px 7px;
  text-align: center;
  line-height: 12px;
}
.status-card__info span.detail > span.asc {
  background-color: #fee2e2;
  color: #ef4444;
}
.status-card__info span.detail > span.dsc {
  background-color: #dcfce7;
  color: #22c55e;
}
.status-card__info span.detail > span > i {
  text-align: center;
  vertical-align: sub;
}

.status-card__info h4 {
  font-size: 2.25em;
  margin-bottom: 10px;
}

.status-card::before {
  content: "";
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-image: linear-gradient(
    to top right,
    var(--main-color),
    var(--second-color)
  );
  position: absolute;
  left: -50%;
  top: 0;
  transform: scale(0);
  transition: transform 0.8s ease 0s;
}

.status-card:hover::before {
  transform: scale(3);
}

.status-card.active::before {
  transform: scale(3);
}

.status-card.active {
  color: var(--txt-white);
}

.status-card:hover {
  color: var(--txt-white);
}

.post-card .post-img {
  width: 7em;
  height: 7em;
  border-radius: 2em;
  border: double rgb(198, 198, 198) 5px;
  display: flex;
  margin-top: -6em;
  margin-right: -1.6em;
  flex-basis: 2.5em;
  flex-grow: 0;
  background-color: rgb(255, 255, 255);
  float: right;
}
.post-card h2 {
  padding-top: 1em;
  font-weight: 900;
  font-size: 1.3em;
}
.post-card .writer {
  margin-top: 1em;
  margin-bottom: 0.3em;
  font-weight: 700;
  color: rgb(137, 137, 137);
}

.post-card hr {
  width: 100%;
  height: 0.1em;
  border-radius: 3px;
  border: none;
}
