.LoadFrame {
  width: 100%;
  height: 100%;
  text-align: center;
}

.loading-element {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading-element .load-img {
  height: 20vh;
  display: block;
}

.loading-element .load-text {
  font-size: 3vh;
  font-weight: 600;
}
