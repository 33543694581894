@import url("//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css");
@import url("https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css");

.spoka * {
  font-family: "NanumSquare", "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
}

body {
  --main-bg: #ffffff;
  --second-bg: #eceffb;
  --table-header: #e0e0e0;
  --txt-color: #212121;
  --txt-gray-color: #757575;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --select-color: #5443ff;
  --box-shadow: rgba(0, 0, 0, 0.03) 0px 10px 8px, rgba(0, 0, 0, 0.03) 10px 0px 8px, rgba(0, 0, 0, 0.03) 0px -10px 8px, rgba(0, 0, 0, 0.03) -10px 0px 8px;

  --main-bg-light: #ffffff;
  --second-bg-light: #eceffb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  --sidebar-width: 280px;
  --border-radius: 12px;
  --topnav-height: 110px;
  --topnav-height: 60px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
}

a {
  text-decoration: none;
  color: unset;
}

/* a:hover {
  color: var(--main-color);
} */

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;
}

input:focus {
  border: 2px solid var(--main-color);
}

.page-header {
  text-transform: capitalize;
  font-size: 1.5em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 40px;
}
.page-header > span {
  font-size: 2.25em;
  line-height: 0;
}
.card {
  padding: 2.5em;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  transition-duration: 1s;
  border-color: rgba(0, 0, 0, 0);
}

/* .card:hover {
  border-color: var(--main-color);
  border-width: 1px;
  border-style: outset;
} */

.full-height {
  height: 100%;
}

/* .card.full-height {
  height: calc(100% - 30px);
} */

.card__header {
  text-transform: capitalize;
  margin-bottom: 1.25em;
}

.card__header > h3 {
  font-size: 1.625em;
  font-weight: 400;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}

.related-keyword {
  height: fit-content;
}
.related-keyword > .card__body {
  padding: 0 2.5em;
}
