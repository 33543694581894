.table-main {
  height: 90%;
}

.table-main .table-wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}

.table-main table {
  width: 100%;
  min-width: 400px;
  border-spacing: 0;
  height: 100%;
  table-layout: fixed;
}

.table-main tbody {
  height: 100%;
}

.table-main tbody td {
  height: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
}

.table-main .table-element {
  padding-left: 20px;
  flex: 1 1;
  min-width: 0;
}

.table-main .table-element .d-row p {
  margin-right: 0px;
  margin-left: auto;
}

.table-main .table-wrapper .check {
  accent-color: var(--select-color);
  width: 1.5em;
  margin-right: 2em;
}

.table-main .table-main .d-row {
  padding-left: 10px;
}

.table-main thead {
  background-color: var(--table-header);
}

.table-main tr {
  text-align: left;
  transition-duration: 130ms;
  height: 10%;
}

.table-main td {
  text-transform: capitalize;
  font-size: 10px;
  padding: 3px 10px;
}
.table-main th {
  text-transform: capitalize;
  font-size: 10px;
  padding: 3px 10px;
}

.table-main tbody > tr:hover {
  background-color: rgba(169, 163, 255, 0.416);
  color: #1500b3;
}

.table-main .logo {
  height: 5em;
  width: 5em;
  border-radius: 100%;
  align-self: center;
}

.table-main .d-row.first {
  margin-left: 0px;
  width: 100%;
  height: 90%;
  align-content: center;
}
.table-main .d-row.second {
  display: flex;
  justify-content: space-between;
}
.table-main .d-row.second div {
  font-weight: 600;
  font-size: 1.5em;
  flex: 1;
}

.table-main .d-row.second p {
  font-size: 1.2em;
}

.table-main .d-row.second .row-header {
  display: flex;
  justify-content: space-between;
}
.table-main .d-row.second .row-nickname {
  margin-right: 2em;
}
.table-main .d-row.second .row-title {
  font-weight: unset;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  line-height: 1.6;
  text-overflow: ellipsis;
}

.mail-post .profile {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.mail-post .profile a {
  padding: 3px;
  border-style: groove;
  border-color: var(--main-color);
  border-radius: 10px;
}

.mail-post .profile {
  margin-left: auto;
}

.mail-post .profile {
  margin-left: auto;
}
.mail-post .profile .detail {
  margin-left: auto;
}
.mail-post .profile span {
  font-size: 23px;
}

.mail-post .profile {
  padding-bottom: 20px;
  border-bottom-style: double;
  border-radius: 0px;
}

.mail-post .content {
  padding-top: 20px;
}

.hate-list-content:hover {
  cursor: pointer;
}

.hate-list-content .row-content {
  font-size: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  line-height: 1.6;
  text-overflow: ellipsis;
}

.hate-list-content .d-row .material-icons-outlined {
  font-size: 1em;
  margin-left: 0.5em;
}