.sidebar {
  min-width: var(--sidebar-width);
  z-index: 2;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  width: var(--sidebar-width);
  padding: 1.25em;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebar__logo {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.375em;
}

.sidebar__logo > img {
  width: 160px;
}

.sidebar__profile {
  width: 100%;
  text-align: center;
  margin-bottom: 4.875em;
}

.sidebar__profile > * {
  margin: 0 auto;
  color: #212121;
}

.sidebar__profile > img {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  margin: 15px;
}

.sidebar__profile > div {
  font-size: 1.125em;
  font-weight: bold;
}

.sidebar__item {
  padding: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1em;
  color: var(--txt-gray-color);
}
.sidebar__item.sub_item {
  font-size: 0.875em;
}
.sidebar__item:hover {
  color: var(--select-color);
}

.sidebar__item.sub_item > div {
  margin-left: 40px;
}

.sidebar__item.current_item {
  background-color: var(--select-color);
  border-radius: 0.5em;
  color: #ffffff;
}

.sidebar__item-inner {
  display: flex;
  align-items: center;
}

.sidebar__item-inner.profile {
  padding: 5px 25px;
  font-weight: 600;
  transition: color 0.3s ease 0s;
  border-bottom: inset var(--txt-color) 1px;
}

.sidebar__item-inner > i:first-child {
  margin-right: 16px;
  font-size: 1.5rem;
}
.sidebar__item-inner > i:last-child {
  position: absolute;
  right: 1.25em;
}

.sidebar__item-inner > img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.sidebar__item-inner > span {
  text-transform: capitalize;
  text-align: center;
}

.sidebar__item-inner > svg {
  position: absolute;
  right: 36px;
}

.sidebar__item-inner.click {
  color: var(--select-color);
  transition-duration: 1s;
}

.sidebar__item-inner.active {
  border-radius: var(--border-radius);
  background-image: linear-gradient(
    to right,
    var(--main-color),
    var(--second-color)
  );
  color: var(--txt-white);
}

@media (max-width: 1280px) {
  .sidebar {
    min-width: 210px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    width: 210px;
    padding: 20px;
    font-size: 0.8em;
  }
  .sidebar__logo > img {
    width: 120px;
  }
  .sidebar__profile > img {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    margin: 12px;
  }
}
