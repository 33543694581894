.profile-container {
  margin-bottom: 16px;
  padding: 0 8px!important;
  font-family: "NanumSquare", "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
}

.profile-container .card {
  padding: 1.5em 0.5em;
}

.profile-thumbnail {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.profile-content {
  text-align: center;
  padding: 0 8px!important;
}

.profile-button {
  padding: 4px 16px;
  font-size: 1.25em;
  border-radius: 8px;
}
