div.error-page {
  align-self: center;
  text-align: center;
  margin-top: 3em;
}
.error-page button {
  padding: 0.5em 1em;
  border-radius: 0.3em;
  margin-right: 1em;
  margin-top: 1em;
}
