.layout {
  color: var(--txt-color);
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;
}

.layout__content-main {
  margin: 0 auto;
  max-width: 1280px;
  min-width: 1000px;
  width: 85%;
  padding-bottom: 64px;
}
.layout__content-main .small-button{
  border: 1px solid #BBBBBB; 
}
.layout__content-main .small-button:nth-child(2){
  border-left: unset;
  border-right: unset; 
}

.card.stack {
  height: calc(100% - 30px);
}

.status-card.keyword {
  height: calc(100% - 30px);
}

.status-card.keyword h4 {
  margin-top: 10px;
}

.circle {
  width: 15px;
  height: 10px;
  background-color: rgb(98, 75, 167);
  border-radius: 40%;
  display: inline-block;
}

.circle.high {
  background-color: rgb(255, 100, 100);
}
.circle.middle {
  background-color: rgb(255, 237, 137);
}

.mail-side .level .line {
  margin-top: 2vh;
}
.mail-side .level span {
  float: none;
}

.mode-list {
  margin-top: 20px;
}

.mode-list > li {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mode-list > li ~ li {
  margin-top: 10px;
}

.mode-list__color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 1.5rem;
}

.mode-list__color > i {
  transform: scale(0);
  transition: transform 0.5s var(--transition-cubic);
}

.mode-list__color.active > i {
  transform: scale(1);
}

.password {
  margin-bottom: 20px;
  width: 70%;
}

.password label {
  color: var(--txt-color);
  top: auto;
  left: auto;
}

.TagForm input {
  width: 100%;
  border: none;
  padding: 10px 0;
  font-size: 16px;
  color: var(--txt-color);
  background: transparent;
  border-radius: 5px;
}

.TagForm {
  width: 100%;
  border-radius: 5px;
  background: var(--main-bg);
  border: 1px solid #e5e5e5;
  margin-bottom: 16px;

  display: flex;
  align-items: center;
}

.TagList {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.TagList {
  width: 70%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Tag {
  background: #4392f1;
  border-radius: 3px;
  padding: 8px 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #ffffff;
}

.Tag:not(:last-child) {
  margin-right: 8px;
}

.InputIcon {
  padding-right: 8px;
  padding-left: 8px;
  color: #7a7a7a;
}

.TagIcon {
  margin-left: 12px;
  cursor: pointer;
}

.Tag2 {
  width: fit-content;
  background: #4392f1;
  border-radius: 3px;
  padding: 8px 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #ffffff;
}

.topic-card {
  border-style: double;
  padding: 50px 0px;
  margin: 5px;
  text-align: center;
  transition-duration: 1s;
}

.topic-card:hover {
  background-color: var(--main-color);
  padding: 50px 30px;
}

.range-select-button {
  border-radius: 4px;
  float: right;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.range-select-button > *:last-child {
  border-radius: 0px 4px 4px 0px;
}
.range-select-button > *:first-child {
  border-radius: 4px 0px 0px 4px;
}

.range-select-button > .small-button {
  font-weight: 900;
}

.small-button {
  padding: 0.625em 1.25em;
  height: 2.5em;
  color: var(--txt-color);
  background: var(--main-bg);
  font-size: 0.775em;
  transition: 0.3s;
  line-height: 0;
}

.small-button.disabled {
  opacity: 50%;
}

.row-line {
  margin: 5px 0px;
  width: 1px;
  background: #e5e5e5;
}

.small-button:hover,.small-button.selected {
  background: #5343ff;
  box-shadow: var(--second-color);
  color: var(--main-bg);
  cursor: pointer;
}
.small-button.disabled:hover {
  background: unset;
  box-shadow: unset;
  color: unset;
  cursor: unset;
}

a:hover {
  cursor: pointer;
}

.normal-button {
  padding: 0px 30px;
  height: 2.5rem;
  color: var(--txt-color);
  background: var(--main-bg);
  font-size: 16px;
  border: 5px outset;
  border-radius: 15px;
  transition: 0.3s;
}

.normal-button:hover {
  background: var(--main-color);
  box-shadow: var(--second-color);
  color: var(--main-bg);
  cursor: pointer;
}

.table-title {
  text-align: center;
  padding-bottom: 0.8em;
  color: #212121;
}
.no-side-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.select-btn {
  height: fit-content;
}
.page-body > div:first-child,
.page-body > div:nth-child(2) {
  overflow: hidden;
}
.page-body > * {
  width: 100%;
  /* overflow: hidden; */
  margin-bottom: 2.5em;
}

.margin-btm-0 {
  margin-bottom: 0;
}

.page-flex {
  display: flex;
  justify-content: space-between;
}

.d-row > [class*="col-"].left {
  padding-left: 0 !important;
}
.d-row > [class*="col-"].right {
  padding-right: 0 !important;
}

.vertical-card-area {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  justify-content: space-between;
}

.card.hate-contents {
  height: 100%;
  padding: 2.5em 0;
}
.card.hate-contents > div {
  padding: 0 2.5em;
}
.card.hate-contents .card__body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
}
.card.hate-contents .card__body > .table {
  flex: 1;
}

.card.social-contents {
  min-height: 50em;
}
.card.social-contents .card__body {
  display: flex;
  min-height: 40.5em;
  width: auto;
  justify-content: space-between;
}
.card.social-contents .card__body > * {
  width: 48%;
  margin-bottom: 0;
}

.card.social-contents .platform-table {
  height: auto;
}

@media (max-width: 1280px) {
  .layout__content {
    padding-left: 210px;
    background-color: var(--second-bg);
    min-height: 100vh;
  }

  .layout__content-main {
    font-size: 0.8em;
    margin: 0 auto;
    max-width: 900px;
    width: 85%;
    padding-bottom: 64px;
    min-width: unset;
  }
}

.rank-main {
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0em;
  margin: 0em -1em;
}

.hcomments-pie-container > div {
  height: unset;
}

.social-row .hcomments-pie-container {
  height: 100%;
}

.social-row .hcomments-pie-container > div {
  height: 100%;
}

.social-row .hcomments-pie-container svg {
  overflow: visible;
}

.social-row .hcomments-pie-container svg * {
  overflow: visible;
}

.social-row
  .hcomments-pie-container
  svg
  .apexcharts-legend.apx-legend-position-right {
  top: -2em !important;
}

.social-row .card__body {
  height: 100%;
}
.social-row .LoadFrame {
  display: flex;
  align-items: center;
}

.social-row .LoadFrame > div {
  width: 100%;
}

.hcomments-barchart-container > div {
  padding: 2.5em 0;
  padding-bottom: 1em;
  height: 24.375em;
}
.hcomments-barchart-container div.card__body {
  padding-right: 1em;
}

.hcomments-barchart-container div.card__header {
  padding: 0 2.5em;
}

/* .modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
} */
.modal .modal-header {
  padding-bottom: 0px;
}

.modal .d-row {
  width: 100%;
}

.modal .logo {
  width: 5vh;
  height: 5vh;
  border-radius: 100%;
  margin-right: 20px;
  margin-bottom: 16px;
}

.modal .title {
  margin-top: 2px;
  align-self: center;
  font-size: 1.3rem;
  font-weight: 600;
}

.modal .date {
  margin-left: auto;
  margin-right: 10px;
  align-self: flex-end;
  font-size: 0.7rem;
  font-weight: 400;
  color: #7a7a7a;
}
.modal .category {
  margin-right: auto;
  font-size: 1rem;
  font-weight: 600;
  color: #7c30ed;
}
.modal .site {
  font-size: 0.8rem;
  font-weight: 600;
  color: #7a7a7a;
}

.modal .description {
  font-size: 0.8rem;
  color: #7a7a7a;
}

.modal .modal-header .btn-close {
  margin-bottom: 8px;
}

.modal .modal__content .content {
  min-height: 30vh;
}

.modal .modal__content .content .comment-parent {
  padding-top: 16px;
  text-align: justify;
}

.modal .modal__content .content .comment-parent .parent {
  font-weight: bold;
  color: gray;
}

.modal .modal__content .content .comment-parent .parent-title {
  font-weight: bold;
}

/* .modal .modal__content .content .comment-parent .parent-content {
} */
.setting-row > div {
  padding-bottom: 2em;
}

.hate-contents {
  width: 100%;
}

.hate-contents table{
  width:100%;
  table-layout: fixed;
  border-collapse: separate; 
  border-spacing: 0px 1em;
}

.hate-contents > div > p {
  font-size: 1.3em;
}

.hate-contents > div{
  overflow-x: hidden;
  overflow-y: hidden;
}

.hate-contents .table-element {
  padding-left: 20px;
  width: 90%;
}

.hate-contents .logo{
  height: 3em;
  width: 3em;
  border-radius: 100%;
  align-self: center;
}

.hate-contents .d-row.first {
  margin-left: 0px;
  width: 100%;
  height: 90%;
  align-content: center;
}
.hate-contents .d-row.second {
  display: flex;
  justify-content: space-between;
}
.hate-contents .d-row.second div {
  font-weight: 600;
  font-size: 1.2em;
  flex: 1;
}

.hate-contents .d-row.second p {
  font-size: 1.2em;
}

.hate-contents .d-row.second .row-header {
  display: flex;
  justify-content: space-between;
}
.hate-contents .d-row.second .row-nickname {
  margin-right: 2em;
}
.hate-contents .d-row.second .row-title {
  font-weight: unset;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  line-height: 1.6;
  text-overflow: ellipsis;
}
.hate-contents .hate-list-content {
  flex:1;
}

.hate-contents .hate-list-content:hover {
  cursor: pointer;
}

.hate-contents .hate-list-content .d-row .material-icons-outlined {
  font-size: 1em;
  margin-left: 0.5em;
}

.hate-contents .hate-list-content .row-content{
  font-size: 1.2em;
  text-overflow: ellipsis;
}

.hate-contents tr > td {
  border: 1px solid #999999 !important;
  border-radius: 0.8em;
  padding: 1em 1.25em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
}


.apexcharts-legend-series[rel] .apexcharts-legend-text {
  font-weight: 700 !important; /* 마우스 호버 시 볼드 */
  cursor: pointer !important;
}