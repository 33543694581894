.daterange {
  float: right;
  display: inline-block;
}

.picklist span::after {
  color: var(--main-color);

  background-color: var(--main-bg);
}

.picklist input:focus {
  border-color: var(--main-color);
  box-shadow: 0px;

  background-color: var(--main-bg);
}

.datepicker h1 {
  color: var(--main-color);
}

.datepicker svg {
  color: var(--main-color);
}

.datepicker {
  border-radius: 30px;
}

.datepicker button:hover {
  border-color: var(--main-color);
}
.datepicker select {
  background-color: var(--main-bg);
  color: var(--txt-color);
}
.datepicker option:hover {
  background-color: var(--main-bg);
  color: var(--txt-color);
}

.datepicker div::after {
  color: var(--main-color);
}
[role="listbox"] {
  background-color: var(--main-bg);
}

[role="listbox"] span {
  color: var(--txt-color);
}

.picklist input {
  background-color: var(--main-bg);
  color: var(--txt-color);
}

[role="listbox"] svg {
  color: var(--main-color);
}

.datepicker [data-selected="true"] {
  background-color: var(--main-color);
}

.datepicker button::after {
  background-color: var(--main-color);
}

.datepicker button {
  color: var(--txt-color);
}
#modal-content-20 {
  background-color: var(--main-bg);
  color: var(--txt-color);
  border-radius: 30px;
}
.datepicker h3 {
  color: var(--txt-color);
}
