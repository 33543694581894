.listpage {
  z-index: 0;
  position: relative;
}

.listpage .mail-side {
  border-right: solid 1px rgba(48, 48, 56, 0.15);
  background-color: #f8f8f8;
  padding: 10px;
}

.listpage .mail-content {
  background-color: #ffffff;
  padding: 0.8em 15px;
}

.listpage .frame {
  min-height: 93vh;
}
.listpage .mail-top1 {
  margin: 0px 20px;
  padding: 0.8em 0px;
  border-bottom: solid 1px rgba(48, 48, 56, 0.243);
  align-items: center;
  min-height: 45px;
  gap: 1em;
}

.listpage .mail-top2 {
  margin: 0px 20px;
  padding: 0.8em 0px;
  border-bottom: solid 1px rgba(48, 48, 56, 0.15);
  align-items: center;
  min-height: 45px;
}

.listpage .mail-main {
  padding: 0px;
  background-color: #ffffff;
}

.listpage .mail-title {
  font-size: 1rem;
  font-weight: 700;
  margin-right: 70px;
}

.listpage .buttons {
  display: inline-flex;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}

.listpage .rightest-item {
  margin-left: auto;
  margin-right: 20px;
}

.listpage .text {
  font-size: 0.75rem;
  font-weight: 600;
  color: #562af4;
  margin-right: 13px;
  align-self: center;
}

.listpage .middle-item {
  margin-right: auto;
  height: 100%;
}

.listpage .menu {
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 0.7em;
}

.listpage .menu i {
  font-size: 1.5em;
  cursor: pointer;
}

.listpage .checkbox {
  margin-right: 26px;
  margin-left: 10px;
  accent-color: var(--select-color);
  width: 15px;
  height: 15px;
}

.listpage .checkbox:hover {
  border: 3px solid #562af4;
}
.listpage .rightest-item a {
  font-size: 0.8rem;
}

.listpage .element {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  transition-duration: 150ms;
  border-radius: 10px;
  margin: 10px 0px;
  cursor: pointer;
}
.listpage .element a {
  font-size: 0.9rem;
  font-weight: 600;
}

.listpage .element:hover {
  background-color: #0000001f;
}

.listpage .element.clicked {
  background-color: #aea4fb9a;
  color: #1500b3;
}

.listpage .element span {
  margin-left: auto;
  font-size: 0.7rem;
  font-weight: 600;
  color: #000000;
}

.table__pagination {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.table__pagination > * {
  z-index: 1;
}
.table__pagination > .material-icons-outlined {
  cursor: pointer;
}
.table__pagination > .material-icons-outlined.disabled {
  cursor: unset;
  opacity: 0.5;
}

.table__pagination-item ~ .table__pagination-item {
  margin-left: 10px;
}

.table__pagination-item {
  /* padding: 0.3rem; */
  width: 2em;
  height: 2em;
  font-size: 1em;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 150ms;
}

.table__pagination-item.active,
.table__pagination-item.active:hover {
  background-color: var(--select-color);
  color: var(--txt-white);
  font-weight: 600;
}

.table__pagination-item:hover {
  color: var(--txt-white);
  background-color: var(--select-color);
  font-weight: 600;
}

.table__pagination-item:disabled {
  color: var(--txt-black);
  background-color: buttonface;
  cursor: default;
}
.table__pagination-item:disabled:hover {
  color: var(--txt-black);
  background-color: buttonface;
}

.searchbar {
  /* width: 30%; */
  display: flex;
  border-radius: 10px;
  background-color: #eaeaea;
  /* top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); */
}

.searchbar .inputBox {
  font-size: 0.8rem;
  width: 30vw;
  border: none;
  margin-left: 5px;
  padding: 0.2rem 0.5rem;
  outline: none;
  background-color: #00000000;
}

.searchbar .inputBox:focus {
  color: #013e46;
}

.searchbar .searchButton {
  text-align: center;
  color: rgb(104, 79, 245);
  margin-right: 5px;
  cursor: pointer;
  font-size: 1rem;
  background-color: #00000000;
}

.list-menu-button .dropdown-toggle {
  font-size: 0.7rem;
  background-color: grey;
  border: none;
}

.list-menu-filter {
  color: #ffffff;
  background-color: grey;
  align-self: center;
  border: none;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.list-menu-filter > span {
  vertical-align: middle;
  font-size: 0.7rem;
}

.list-menu-filter > span.material-icons-outlined {
  vertical-align: middle;
  font-size: 1rem;
  margin-left: 0.3em;
}

.list-menu-button .dropdown-toggle:hover {
  background-color: grey;
}
.list-menu-button .dropdown-toggle:focus {
  border: none;
  background-color: #562af4;
}

/* .list-menu-button .dropdown-item {
  font-size: 0.7rem;
} */
.list-menu-button .dropdown-item:focus {
  background-color: #562af4;
}

.list-menu-button .dropdown-menu {
  min-width: 10px;
}

.filter-box {
  background-color: #ffffff;
  color: var(--txt-color);
  display: flex;
}

.filter-box > div {
  padding: 0.5em;
}
.filter-box > div:first-child {
  border-right: 1px solid;
}

.filter-current {
  border-radius: 10px;
  border: 1px solid;
  font-size: 0.8em;
  margin-left: 2em;
  padding: 0.5em;
}
.filter-current > .material-icons-outlined {
  font-size: 1em;
  cursor: pointer;
  margin-left: 0.2em;
}
.filter-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}
.filter-buttons > div {
  border-radius: 0.5em;
  border: 1px solid;
  cursor: pointer;
  padding: 0.3em 0.6em;
}

#dropdown-basic-button-filter {
  width: 86px;
}
.filter-box-site {
  align-self: center;
}
.filter-box-user {
  text-align: center;
}
.listpage-timestamp {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: auto 0 0 auto;
}
.listpage-timestamp .MuiInputBase-input {
  padding: 0.5em 0.8em;
}
