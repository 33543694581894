.signin {
  background: url(/public/back/login.png);
  background-size: 70%;
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: rgb(0, 54, 119);
}

.sign-right {
  width: 50%;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  text-align: center;
  border-width: 20px;
  border-color: rgb(0, 54, 119);
  border-style: double;
}

.signin img {
  width: 50%;
}

.signin input {
  width: 300px;
}

.signin .MuiTextField-root {
  margin: 20px;
}

.login-bt {
}
